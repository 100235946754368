export const APP_PATH_KEY = 'wingman';

const ADMIN_GROUP_ID = process.env.REACT_APP_ADMIN_GROUP_ID;
const CORE_GROUP_ID = process.env.REACT_APP_CORE_GROUP_ID;
const CORE_MARKETING_GROUP_ID = process.env.REACT_APP_CORE_MARKETING_GROUP_ID;
const SALES_MANAGERS_GROUP_ID = process.env.REACT_APP_SALES_MANAGERS_GROUP_ID;
const KFS_SUPPORT_GROUP_ID = process.env.REACT_APP_KFS_SUPPORT_GROUP_ID;

export const APP_ROLES = {
    KIT_REQUEST: 'KIT_REQUEST',
    RESOURCES: 'RESOURCES',
    SALES_FORMS: 'SALES_FORMS',
    LOCATIONS: 'LOCATIONS',
    REPORTING: 'REPORTING',
};

export const APP_PERMISSIONS = {
    [APP_ROLES.KIT_REQUEST]: [ADMIN_GROUP_ID],

    [APP_ROLES.RESOURCES]: [
        ADMIN_GROUP_ID,
        KFS_SUPPORT_GROUP_ID,
        CORE_MARKETING_GROUP_ID,
        CORE_GROUP_ID,
        SALES_MANAGERS_GROUP_ID,
    ],

    [APP_ROLES.SALES_FORMS]: [ADMIN_GROUP_ID, KFS_SUPPORT_GROUP_ID],

    [APP_ROLES.LOCATIONS]: [ADMIN_GROUP_ID, KFS_SUPPORT_GROUP_ID],

    [APP_ROLES.REPORTING]: [
        ADMIN_GROUP_ID,
        KFS_SUPPORT_GROUP_ID,
        SALES_MANAGERS_GROUP_ID,
    ],
};
